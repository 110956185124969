body {
    .container {
        max-width: 1100px;
        width: 100%;
        margin: 0 auto;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    }

    .top-wrapper {
        background-image: url("./assets/light.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .top-wrapper .container,
    .platform .container,
    .work .container,
    .personal .container,
    .grow .container {
        display: flex;
        padding: 50px 20px 50px 20px;
        position: relative;
    }

    .row {
        position: relative;
        display: flex;
        flex-flow: column;
        z-index: 9;
        max-width: 33%;
        width: 100%;
    }

    .row .col {
        padding: 57px;
        background-color: rgba(255, 255, 255, 0.15);
        border-radius: 15px;
        height: 250px;
        width: 90%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        margin-bottom: 30px;
    }

    .row.center {
        justify-content: end;
    }

    .row h1 {
        font-size: 32px;
        font-weight: 600;
        margin-top: 0;
        margin-bottom: 5px;
    }

    .row span {
        font-style: italic;
    }


    .platform {
        position: relative;
        z-index: 9;
        padding: 100px 0 80px;
    }

    .platformTopShape {
        position: absolute;
        top: 0;
        right: 0;
        background-image: url("./assets/About&contact_shape_1.png");
        background-position-x: right;
        background-position-y: bottom;
        background-repeat: no-repeat;
        background-size: contain;
        height: 150px;
        width: 100%;
    }

    .platformBottomShape {
        position: absolute;
        bottom: 0;
        left: 0;
        background-image: url("./assets/About&contact_shape_2.png");
        background-position-x: left;
        background-position-y: bottom;
        background-repeat: no-repeat;
        background-size: contain;
        height: 300px;
        width: 100%;
    }

    .platform .container,
    .personal .container {
        justify-content: space-between;
    }

    .platform h1,
    .personal h1 {
        margin-bottom: 0;
        margin-top: 0;
        color: #5f8685;
        font-weight: 200;
        font-size: 46px;
        line-height: 1.2;
    }

    .platform p,
    .personal p {
        font-size: 16px;
        font-weight: 500;
    }

    .platform .text,
    .personal .text {
        max-width: 55%;
    }

    .platform .image {
        max-width: 43%;
        width: 100%;
        background-image: url("./assets/cyber.jpg");
        background-position: start;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .work {
        background-image: url("./assets/hands.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .work .container {
        flex-flow: column;
    }

    .work .textWrapper {
        max-width: 50%;
        margin-left: auto;
        padding: 70px 0;
    }

    .work h1 {
        color: #5f8685;
        font-weight: 300;
        font-size: 46px;
        margin-bottom: 0;
    }

    .work p {
        font-weight: 500;
        margin-top: 8px;
    }

    .personal img {
        max-width: 43%;
        width: 100%;
        object-fit: contain;
    }

    .personal {
        position: relative;
        padding: 90px 0 150px 0;
    }

    .personalTopShape {
        position: absolute;
        top: -1px;
        left: 0;
        background-image: url("./assets/About_shape_3.png");
        background-position-x: left;
        background-position-y: bottom;
        background-repeat: no-repeat;
        background-size: contain;
        height: 300px;
        width: 100%;
    }

    .personalBottomShape {
        position: absolute;
        bottom: 0;
        left: 0;
        background-image: url("./assets/About_shape_4.png");
        background-position-x: left;
        background-position-y: bottom;
        background-repeat: no-repeat;
        background-size: cover;
        height: 300px;
        width: 100%;
    }

    .grow {
        background-image: url("./assets/sunset.jpg");
        background-position: start;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .grow {
        height: 500px;
    }

    .grow .container {
        padding-top: 100px;
    }

    .grow .text {
        max-width: 43%;
        margin-left: auto;
    }

    .grow h1 {
        max-width: 87%;
        color: #d37e5f;
        font-weight: 300;
        font-size: 40px;
        line-height: 1.2;
    }



    @media only screen and (max-width: 1000px) {
        .row .col {
            padding: 30px;
            height: 280px;
        }
    }

    @media only screen and (max-width: 704px) {
        .top-wrapper .container {
            flex-flow: column;
        }

        .row {
            max-width: 100%;
            align-content: center;
            flex-wrap: wrap;
        }

        .row .col {
            max-width: 350px;
            padding: 57px;
            height: 250px;
            margin: 0 auto 30px;
        }
    }

    @media only screen and (max-width: 768px) {
        .row {
            flex-flow: column;
        }

        .platform .container,
        .personal .container {
            flex-flow: column;
        }

        .platform .text,
        .personal .text,
        .grow .text,
        .grow h1 {
            max-width: 100%;
        }

        .platform .image,
        .personal img {
            max-width: 100%;
            height: 300px;
        }

        .work .textWrapper {
            max-width: 100%;
        }

        .platform {
            padding: 40px 0 45px;
        }

        .platformTopShape {
            background-position-x: center;
            background-position-y: bottom;
            background-size: cover;
        }

        .platformBottomShape {
            background-position-x: center;
            background-position-y: center;
            background-size: cover;
        }

        .personal {
            padding: 50px 0 80px 0;
        }

        .personalTopShape {
            background-position-y: top;
            background-size: cover;
        }

        .personalBottomShape {}
    }

}