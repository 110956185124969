div.cookiesContainer {
    max-width:100%;
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    align-items: center;
    padding: 30px 20px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.45);
    background-color: #fff;
}
.cookiesLink {
    color: #5f8685;
}

.cookiesBtn {
    background: #d37e5f !important;
}

@media only screen and (max-width: 768px) {
    div.cookiesContainer {
        flex-flow: column;
        text-align: center;
    }

    .cookiesBtn {
        margin-top: 15px !important;
    }
}