.contactWrapper {
    max-width: 100%;
    width: 100%;
    background-image: url("./assets/Contact_1&2.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding: 20px 0 150px;
    background-position-x: center;
    background-position-y: center;
}

.boxesWrapper {
    display: flex;
    justify-content: space-between;
}

.contactContent {
    display: flex;
    flex-direction: column;
    padding-top: 40px;
}

.contactTitle {
    color: #5f8685;
}

p.contactText {
    margin-top: 20px;
}

.contactLeft,
.contactRight {
    max-width: 47%;
    width: 100%;
    margin-top: 25px;
}

h4.secondTitle {
    color: #d37e5f;
    font-weight: 100;
}

h4.cityTitle {
    color: #5f8685;
    margin-top: 40px;
}

a.phone,
.address {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

a.phone svg,
.address svg {
    margin-right: 10px;
}

a.phone strong {
    margin-left: 5px;
}

.cityImage {
    background-image: url("./assets/dreamstime.jpg");
    width: 100%;
    height: 200px;
    background-size: cover;
    margin-top: 20px;
    background-position-x: center;
    background-position-y: center;
}

form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

form select::placeholder,
form input::placeholder,
form textarea::placeholder {
    color: black;
}

form select,
form input,
form textarea {
    padding: 15px;
    margin: 7px 0;
    border-radius: 4px;
    border-style: solid;
    border-width: 2px;
}

form textarea {
    height: 134px;
}

.full {
    max-width: 100%;
    width: 100%;
}

.half {
    max-width: 48%;
    width: 100%;
}

#terms {
    margin-right: 15px;
}

.formBottom {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

button.submitButton {
    background: white;
    border-radius: 3px;
    padding: 8px 30px;
    margin-right: 20px;
    border-style: solid;
}

button.submitButton:hover {
    background: white;
}

button.cancelButton {
    background: #d37e5f;
    color: white;
    box-shadow: none;
    border-radius: 3px;
    padding: 8px 30px;
}

.terms {
    margin-top: 5px;
    margin-bottom: 10px;
}

.red {
    color: red;
    margin-right: 15px;
}

@media only screen and (max-width: 768px) {
    .boxesWrapper {
        flex-direction: column;
    }

    .contactLeft,
    .contactRight {
        max-width: 100%;
        width: 100%;
        margin-top: 25px;
    }

    .contactRight {
        margin-bottom: 100px;
    }

    h4.cityTitle {
        margin-top: 20px;
    }

    form select,
    form input,
    form textarea {
        width: 100%;
    }

    .half {
        max-width: 100%;
    }

    .terms {
        display: flex;
    }

    #terms {
        max-width: 15px;
    }

    .terms label {
        line-height: 1;
    }

    .formBottom {
        flex-flow: column;
    }

    .buttons {
        display: flex;
        justify-content: space-around;
        margin-top: 10px;
    }
}