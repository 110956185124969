div.cookieWrapper {
    max-width: 100%;
    position: relative;
}

.topShape {
    background-image: url("./assets/cookies_shape_1.png");
    height: 200px;
    position: absolute;
    width: 100%;
    left: 0;
    background-size: cover;
    background-position-x: right;
    background-position-y: center;
}

.cookieContent {
    z-index: 9;
    display: flex;
    flex-direction: column;
    padding-top: 80px;
    padding-bottom: 180px;
}

h3.cookieTitle {
    color: #5f8685;
    margin-bottom: 20px;
}

.cookieSubtitle {
    font-weight: 200;
    color: #d37e5f;
    margin: 5px 0;
}

.cookieList {
    padding-left: 18px;
    margin: 0;
}

.cookieListSecondary {
    list-style: none;
}

.cookieText {
    margin: 5px 0 5px 0;
}

.bottomShape {
    background-image: url("./assets/cookies_shape_2.png");
    position: absolute;
    height: 200px;
    width: 100%;
    left: 0;
    bottom: 0;
    background-size: cover;
    background-position-x: right;
    background-position-y: bottom;
}

@media only screen and (max-width: 768px) {
    .topShape,
    .bottomShape {
        background-position-x: center;
    }

    .cookieContent {
        padding-bottom: 100px;
    }
}