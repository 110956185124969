.homeWrapper {
    max-width: 100% !important;
    background-image: url("./assets/Home.png");
    background-position-x: center;
    background-position-y: bottom;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 20px 0 250px;
}

h2.homeTitle {
    color: #5f8685;
    font-weight: 400;
}

h4.homeSubtitle {
    font-weight: 200;
    max-width: 450px;
    margin-top: 20px;
    margin-bottom: 120px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

@media only screen and (max-width: 768px) {
    .homeWrapper {
        padding: 20px 0 40px;
    }

    .gridWrapper {
        display: flex;
        flex-flow: column !important;
    }

    div.homeText,
    div.homeLogin {
        max-width: 100%;
        width: 100%;
    }

    h4.homeSubtitle {
        max-width: 100%;
    }

    div.homeText {
        text-align: center;
    }
}

@media only screen and (min-width: 768px) {
    .homeText {
        margin-bottom: 150px;
    }
}