.nav-wrapper .logo {
    display: flex;
}

.nav-wrapper .logo div {
    height: 29px;
    margin-top: 16px;
    margin-left: 10px;
}

.nav-wrapper img {
    width: 64px;
    height: 64px;
}

@media only screen and (max-width: 768px) {
    header>div {
        justify-content: center;
    }

    div.nav-wrapper {
        justify-content: center;
        margin-left: 0;
        margin-right: 0;
        flex-flow: column;
    }

    .nav-wrapper img {
        width: 50px;
        height: 50px;
    }

    .nav-wrapper .logo div {
        display: flex;
        align-content: center;
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 384px) {
    .nav-items a {
        padding: 12px 8px;
    }

}